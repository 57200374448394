1<template>
  <a-spin tip="Loading..." :spinning="spinning">
    <div id="sendTable" style="padding: 0 15px" ref="sendTable">
      <div class="title position-re flex flex-ai-c flex-jc-c">
        <span>{{ formObj.name }}</span>
        <div
          class="upload_tab position-ab flex flex-ai-c flex-jc-c cursor-p"
          @click="uploadFileTransform"
          v-show="isShow"
        >
          下载表单
          <img :src="upload_img" />
        </div>
      </div>
      <table class="mytb draft_table" style="border: none">
        <tr class="thtt">
          <th>发文字号</th>
          <td colspan="3" class="textLeft padding10">
            {{ formObj.documentNumber }}
          </td>
        </tr>
        <tr class="thtt">
          <th>紧急程度</th>
          <td class="textLeft padding10">
            {{ formatSelect('flow_urgency_degree', formObj.jjcd) }}
          </td>
          <th>密级程度</th>
          <td class="textLeft padding10">
            {{ formatSelect('flow_secrecy_degree', formObj.miji) }}
          </td>
        </tr>
        <tr class="thtt">
          <th colspan="1">拟稿单位</th>
          <td colspan="3" class="text-a-l padding10">
            <template v-for="(o, i) in getDraftCompany(formObj.draftCompany)">
              <a-tag class="tags flex0" color="blue" v-if="o.corpName" :key="'ngdw_list_span' + i">{{ o.corpName }}</a-tag>
              <a-tag
                class="tags flex0"
                :key="'ngdw_list_tag' + i"
                color="blue"
                v-else
              >
                <OpenData :type="o.type" :openid="o.departmentId" :corpid="o.corpId"></OpenData>
              </a-tag>
            </template>
            <!-- <a-tag
              class="tags flex0"
              color="blue"
              v-for="(o, i) in getDraftCompany(formObj.draftCompany)"
              :key="'ngry_list' + i"
            >
              <OpenData :type="o.type" :openid="o.departmentId" :corpid="o.corpId"></OpenData>
            </a-tag> -->
          </td>
        </tr>
        <tr class="thtt">
          <th>拟稿人</th>
          <td class="textLeft padding10">
            <a-tag
              class="tags flex0"
              color="blue"
              v-for="(o, i) in getDraftCompany(formObj.draftId)"
              :key="'ngry_list' + i"
            >
              <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
            </a-tag>
          </td>
          <th>联系电话</th>
          <td class="textLeft padding10">
            {{ formObj.tellPhone }}
          </td>
        </tr>
        <tr class="thtt">
          <th colspan="1">核稿人</th>
          <td colspan="3" class="text-a-l padding10">
            <a-tag
              class="tags flex0"
              color="blue"
              v-for="(o, i) in getDraftCompany(formObj.checkIds)"
              :key="'ngry_list' + i"
            >
              <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
            </a-tag>
          </td>
        </tr>
        <tr class="thtt">
          <th>文稿标题</th>
          <td colspan="3" class="textLeft padding10">
            {{ formObj.title }}
          </td>
        </tr>

        <tr class="thtt">
          <th>正文</th>
          <td colspan="3" class="textLeft padding10">
            <div class="filsContainer">
              <div class="flex flex-ai-c" v-for="(o, i) in formObj.attach" :key="i">
                <span class="pre-view-file" title="点击预览" @click="preView(o)">{{ o.fileName }}</span>
                <fileDownAndPreView :file="o" />
              </div>
            </div>
          </td>
        </tr>
        <tr class="">
          <th>附件</th>
          <td colspan="3" class="textLeft padding10">
            <div class="filsContainer">
              <div class="flex flex-ai-c" v-for="(o, i) in formObj.fileIds" :key="i">
                <span class="pre-view-file" title="点击预览" @click="preView(o)">{{ o.fileName }}</span>
                <fileDownAndPreView :file="o" />
              </div>
            </div>
          </td>
        </tr>

        <!-- <tr class="bigtt">
          <th>文件摘要</th>
          <td colspan="3">
            <a-textarea readOnly v-model="formObj.summary" :auto-size="{ minRows: 5 }" />
          </td>
        </tr> -->

        <!-- <tr class="bigtt">
          <th>拟办意见</th>
          <td colspan="3">
            <a-textarea readOnly v-model="formObj.deviseOpinion" :auto-size="{ minRows: 5 }" />
          </td>
        </tr> -->

        <tr class="thtt">
          <th rowspan="2">内部会稿意见</th>
          <td colspan="3" class="text-a-l box-bb"><a-input readOnly v-model="formObj.meetCompany" /></td>
        </tr>
        <tr class="bigtt">
          <td colspan="3" class="text-a-l box-bb">
            <a-textarea readOnly v-model="formObj.meetDesc" :auto-size="{ minRows: 5 }" />
          </td>
        </tr>

        <tr class="thtt">
          <th rowspan="2">外部会稿意见</th>
          <td colspan="3" class="text-a-l box-bb"><a-input readOnly v-model="formObj.outDraftCompany" /></td>
        </tr>
        <tr class="bigtt">
          <td colspan="3" class="text-a-l box-bb">
            <a-textarea readOnly v-model="formObj.outDraftDesc" :auto-size="{ minRows: 5 }" />
          </td>
        </tr>
        <nodes :list="formatList(list)" />
      </table>
    </div>
  </a-spin>
</template>
  
  <script>
import minxin from '@/views/fileStream/archived/minxins.js'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
import { downLoadAndPre } from '@/mixins/index.js'
export default {
  props: {
    content: {
      type: String,
      default: '输入正文',
    },
    formObj: {
      type: Object,
      default() {
        return { name: '发文稿纸' }
      },
    },
  },
  mixins: [minxin,downLoadAndPre],
  components: {
    OpenData,
    fileDownAndPreView,
    nodes,
  },
  data() {
    return {
      ngdw_list: [],
      ngry_list: [],
    }
  },
  computed: {
    list() {
      if (this.formObj.documentNodeList && this.formObj.documentNodeList.length) {
        const nodes = this.formObj.documentNodeList.slice()
        return nodes.filter((j) => j.sort !== -1 && ![99, 100].includes(j.sort))
      }
      return []
    },
  },
  methods: {
    formatListMess(node) {
      const arr = []
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          dep.deptUsers.forEach((k) => {
            arr.push(k)
          })
        })
      }
      node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
    },
    formatList(list) {
      const listCopy = JSON.parse(JSON.stringify(list))
      listCopy.forEach((j) => {
        this.formatListMess(j)
      })
      return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
    },
  },
}
</script>
  <style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
::v-deep .ant-select-selection,
::v-deep .ant-input {
  border: none;
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}

.draft_content {
  flex-direction: column;
  .draft_company {
    flex: 1;
    // background: rebeccapurple;
    border-bottom: 1px solid #000;
  }
  .draft_proposal {
    flex: 3;
    // background-color: aquamarine;
  }
}

.upload_file {
  display: flex;
  align-items: center;
  line-height: 0.7;
}
.font {
  font-size: 16px;
}

.filsContainer {
  min-height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  .upload_file {
    margin-bottom: 8px;
  }
  .pre-view-file{
    transition: 0.5s;
    cursor: pointer;
  }
  .pre-view-file:hover{
    color: #1890ff;
  }
}

.tags {
  margin-bottom: 2px;
}
.upload_text {
  // cursor: pointer;
  &.htmlContainer {
    max-height: 200px;
    overflow: auto;
  }
}
.padding10 {
  padding: 10px;
}
.mytb {
  width: 100%;
  border: 1px solid black;
  font-size: 14px;
  table-layout: fixed;
  th,
  td {
    text-align: center;
    border: 1px solid black;
    // padding-left: 11px;
    &.textLeft {
      text-align: left;
    }
  }
  .thtt {
    height: 50px;
  }
  .tdtt {
    height: 50px;
  }
  .bigtt {
    height: 150px;
  }
  .smalltt {
    height: 50px;
  }
  .align_l {
    td {
      text-align: left;
      padding: 15px;
      vertical-align: text-top;
      white-space: pre-line;
    }
  }
}
.draft_table {
  .list_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .my-item {
      width: 40%;
      margin: 0 5px;
    }
    .list_btn {
      width: 100%;
    }
  }
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  ::v-deep .ant-select-selection,
  ::v-deep .ant-input {
    border: none;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
}
.tags {
  line-height: 32px;
}
.title {
  height: 60px;
  width: 100%;
  margin: 0 auto;
  color: red;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin-bottom: 15px;
  .upload_tab {
    transform: translateY(-50%);
    right: 0;
    top: 60%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1890ff;
    img {
      height: 16px;
      width: 16px;
      margin-left: 3px;
    }
  }
}
</style>
  